import React, { useContext } from "react";

import ThemeContext from "../../context/ThemeContext";

import "./AboutSection.css";

import auth from "../../services/authServices";
import OldPaper from "../../assets/adel-assets/patternHistory.png";
import oldBG from "../../assets/adel-assets/oldBg.png";
import oldBgCard1 from "../../assets/adel-assets/oldCardBg1.png";
import oldBgCard2 from "../../assets/adel-assets/oldCardBg2.png";

import { ReactComponent as Tree } from "../../assets/adel-assets/tree.svg";
export const Features = [
    {
        title: "علي رواقة",
        desc: "منصتك بتجمع لك كل المنهج، عشان تذاكر براحتك في أي وقت ومن أي مكان",
        img: oldBgCard1,
    },
    {
        title: "في ضهرك",
        desc: "فريقنا الفني جاهز لحل أي مشكلة، وهنوفر ليك دعم مستمر في كل وقت",
        img: oldBgCard2,
    },
    {
        title: "يلا نقفلها",
        desc: "مستواك في التاريخ هيكون ممتاز وهيختلف مع فيديوهاتنا وطريقة التعليم التفاعلية.",
        img: oldBgCard1,
    },
];

const AboutSection = () => {
    let { darkmode } = useContext(ThemeContext);

    return (
        <div className="relative">
            <div className=" absolute inset-0 -z-10 flex justify-evenly flex-col">
                <div className=" w-screen  h-2 bg-stone-500  " />
                <div className=" w-screen  h-2 bg-stone-500  " />
            </div>
            <div>
                <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute -top-14 left-[10%]" />
                <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute -top-14 right-[10%]" />
                <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute hidden md:block -top-14 right-1/2" />
            </div>
            <section className="relative pb-20 flex justify-center w-full bg-transparent overflow-hidden">
                <div className="w-[98%] sm:w-[90%] min-h-[500px] smooth border border-black flex-center-both flex-col relative z-10 py-14 sm:p-14">
                    {/* Start build content  */}
                    <div
                        className="w-full h-full z-10 top-0 bottom-0 left-0 right-0 absolute opacity-20"
                        style={{
                            backgroundImage: "url(" + OldPaper + ")",
                            backgroundSize: "contain",
                            backgroundPosition: "center center",
                            // backgroundRepeat: "repeat-both",
                        }}
                    ></div>
                    <div
                        className="w-full h-full z-0 top-0 bottom-0 left-0 right-0 absolute opacity-100"
                        style={{
                            backgroundImage: "url(" + oldBG + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            // backgroundRepeat: "repeat-both",
                        }}
                    ></div>
                    <div className="absolute inset-0 z-10 bg-black/0 smooth dark:bg-black/25 " />

                    <div className="Features__cards relative z-10 flex justify-center py-8 flex-wrap gap-8">
                        {Features.map((feature, index) => (
                            <div
                                key={index}
                                className="group  h-[350px] smooth relative overflow-hidden flex flex-col justify-between w-[95%] sm:max-w-[330px] text-center p-8 gap-6 text-black shadow-xl smooth "
                            >
                                <div className="absolute inset-0 z-10 bg-black/0 smooth dark:bg-black/25 " />
                                <div
                                    className="w-full h-full z-0 top-0 bottom-0 left-0 right-0 absolute"
                                    style={{
                                        backgroundImage: "url(" + feature.img + ")",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        // backgroundRepeat: "repeat-both",
                                    }}
                                ></div>
                                <div className="relative z-10 flex gap-4 flex-col-reverse items-center  justify-center">
                                    <h3 className="font-dg text-3xl font-dg">{feature.title}</h3>
                                    <span className="p-3 x bg-tan-300 dark:bg-tan-600 smooth  rounded-full">
                                        {feature.Icon}
                                    </span>
                                </div>

                                <p className="relative z-10 text-xl font-lama text-center w-3/4 md:w-full">
                                    {feature.desc}{" "}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutSection;
