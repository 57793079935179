import React from "react";

import Button from "../../components/ui/Button";

import auth from "../../services/authServices";

import sandPattern from "../../assets/adel-assets/sandPattern.png";
import EgyptianPyramids from "../../assets/adel-assets/EgyptianPyramids.png";
import { Link } from "react-router-dom";
import oldPaperIcon from "../../assets/adel-assets/OldPaperIcon.png";
import { ReactComponent as Tree } from "../../assets/adel-assets/tree.svg";

const CTASection = () => {
    const token = auth.getToken();

    return (
        <div className="w-full px-4 flex flex-col md:flex-row-reverse relative py-20">
            <div className="basis-full flex-center-both md:basis-1/2">
                <div className="flex-center-both opacity-60">
                    <img src={EgyptianPyramids} alt="EgyptianPyramids" />
                </div>
            </div>
            <div className="basis-full md:basis-1/2 flex-center-both">
                <div className="flex bg-[#FEF3CD] overflow-hidden pt-10 max-w-[600px] dark:bg-stone-700 smooth justify-center relative z-10 items-center flex-col pb-20">
                    <div>
                        <Tree className="w-20 fill-stone-700 dark:fill-yellow-500 smooth h-20 absolute -bottom-5 left-5" />
                        <Tree className="w-20 fill-stone-700 dark:fill-yellow-500 smooth h-20 absolute -bottom-5 right-5" />
                    </div>
                    <div
                        className="w-full h-full z-0 top-0 bottom-0 left-0 right-0 opacity-20 absolute"
                        style={{
                            backgroundImage: "url(" + sandPattern + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            // backgroundRepeat: "repeat-both",
                        }}
                    ></div>
                    <h1 className="text-4xl sm:text-7xl font-dg  relative z-10 font-bold clr-text-primary smooth ">
                        <p className={`text-[#FFAD00] text-stroke inline-block relative`}>
                            <span>تاريخك هنا</span>
                        </p>
                    </h1>

                    <div className=" flex-center-both relative z-10 items-center">
                        <div className="clr-text-primary smooth">
                            <div className=" overflow-hidden flex-center-both space-y-10 md:space-y-20 flex-col  relative  smooth rounded-md py-5 px-5 ">
                                <div className="z-10 relative flex-center-both flex-col text-center md:text-right space-y-5 ">
                                    <p className="paragraph-text clr-text-primary smooth text-lg md:text-xl w-[90%] mx-auto py-5  md:mx-0  md:w-2/3 flex flex-col space-y-4">
                                        كلام مؤرخين !! مستر أحمد عادل مبيقولش غير كده و جابلك طريقة
                                        خفيفة ولذيذة عشان تتعلم التاريخ، عشان التاريخ ما يبقاش
                                        كابوس!
                                        <br />
                                        <span className="pt-5">
                                            وده كله عشان تدمن المادة، وتتعلم من غير ما تحس بالملل!
                                        </span>
                                    </p>
                                </div>

                                <div className="my-6 mx-auto lg:mx-0 w-fit">
                                    <div className="btn-holder flex justify-center md:justify-start pt-10">
                                        <Link
                                            className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-stone-500 before:border-t-stone-500 after:border-r-stone-500 after:border-b-stone-500 "
                                            to={`${token ? "/me/user/courses" : "/register"}`}
                                        >
                                            <div className="bg-stone-500 dark:bg-[#FFAD00]  smooth px-8 py-3 flex-center-both gap-4">
                                                <img
                                                    src={oldPaperIcon}
                                                    alt="oldPaperIcon"
                                                    className="h-6"
                                                />{" "}
                                                <span className="text-link clr-white smooth dark:text-black text-lg">
                                                    {token ? <>كورساتي </> : <>انضم لعيلتنا</>}
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CTASection;
