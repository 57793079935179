import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import FooterLink from "./components/FooterLink";

import { useLocation } from "react-router-dom";
import { adminPath, teachersubadminPath } from "../../services/defaultSettings";
const Footer = () => {
    const location = useLocation();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (
            location.pathname.includes(adminPath) ||
            location.pathname.includes(teachersubadminPath)
        ) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);

    //     للتواصل مع دعم الاونلاين 01062118478
    // للتواصل مع دعم السناتر 01097697505
    // للتواصل مع المادة العلميه 01023160433
    // كله ده واتساب بس
    return (
        <div
            className={`footer bg-slate-700 py-20 clr-white flex-center-both flex-col space-y-6 w-full ${className}`}
        >
            <div className="flex-center-both space-x-5 space-x-reverse">
                <FooterLink
                    link="https://www.youtube.com/@mrahmedadel9962"
                    className={`bg-gradient-to-r from-rose-500 to-red-500 `}
                    icon={<Icon icon="ant-design:youtube-filled" />}
                />
                <FooterLink
                    link="https://www.facebook.com/groups/206423153792493"
                    className={`bg-gradient-to-r from-cyan-500 to-blue-500 `}
                    icon={<Icon icon="fluent:people-community-48-filled" />}
                />
                <FooterLink
                    link="https://www.facebook.com/profile.php?id=100068388489977&mibextid=LQQJ4d"
                    className={`bg-gradient-to-r from-cyan-500 to-blue-500 `}
                    icon={<Icon icon="fa6-brands:facebook" />}
                />
            </div>
            <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div>
            <div className="flex-center-both space-x-5 space-x-reverse px-5">
                <span className="font-h2 ">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
                <span className="text-slate-200 text-center shrink">
                    تم صنع هذه المنصة بهدف تهيئة الطالب لـ كامل جوانب الثانوية العامة و ما بعدها
                </span>
                <span className="font-h2">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
            </div>
            {/* WhatsApp Support Section */}
            <div className="text-center bg-yellow-200 w-3/5 md:min-w-[400px] smooth py-5 text-slate-900 px-10">
                <div className="flex-center-both flex-row-reverse gap-4">
                    <h4 className="font-bold text-3xl mb-2">للتواصل عبر واتساب</h4>

                    <Icon icon="logos:whatsapp-icon" className="text-4xl mb-1" />
                </div>
                <div className="flex flex-col space-y-2">
                    <a
                        href="https://wa.me/201062118478"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline hover:bg-stone-500 smooth py-1 hover:text-slate-100 rounded-md"
                    >
                        تواصل مع دعم الاونلاين{" "}
                    </a>
                    <a
                        href="https://wa.me/201097697505"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline hover:bg-stone-500 smooth py-1 hover:text-slate-100 rounded-md"
                    >
                        تواصل مع دعم السناتر
                    </a>
                    <a
                        href="https://wa.me/201023160433"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline hover:bg-stone-500 smooth py-1 rounded-md hover:text-slate-100"
                    >
                        تواصل مع المادة العلمية
                    </a>
                </div>
            </div>
            <div className="en text-center font-com space-x-2 opacity-60 px-5 flex flex-wrap flex-center-both">
                <span className="font-w-bold space-x-1">
                    <span className="text-purple-600">&#60;</span>
                    <span className="text-purple-300">Developed By</span>
                    <span className="text-purple-600">&#62;</span>
                </span>
                <span>
                    <a
                        href="https://www.facebook.com/Om4rS4Ieh/"
                        className="bg-slate-700 hover-shadow smooth px-2 py-2 rounded-md"
                    >
                        Omar
                    </a>
                    <span>,</span>
                    <a
                        href="https://www.facebook.com/emad.sharf.16"
                        className="bg-slate-700 hover-shadow smooth px-3 py-1 rounded-md"
                    >
                        Emad
                    </a>
                </span>
                <span className="font-w-bold space-x-1">
                    <span className="text-purple-600">&#60;</span>
                    <span className="text-purple-300">
                        All Copy Rights Reserved @{new Date().getFullYear()}
                    </span>
                    <span className="text-purple-600">&#62;</span>
                </span>
            </div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;
