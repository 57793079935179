import React, { useContext, useState } from "react";
import Electric from "../../components/svgs/Electric";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/adel-assets/HeroImg.png";
import bg from "../../assets/bg-star.png";
import bgdark from "../../assets/bg-star-dark.png";
import bgtop from "../../assets/bg-top.png";
import bgbottom from "../../assets/bg-bottom.png";
import ThemeContext from "../../context/ThemeContext";
import oldPaperIcon from "../../assets/adel-assets/OldPaperIcon.png";
import { Link } from "react-router-dom";
import CenterIcon from "../../components/ui/CenterIcon";
import { ReactComponent as Mountatain } from "../../assets/adel-assets/moutanis.svg";
const MainSection = ({ title = false, id = 0 }) => {
    const { token } = useContext(AuthContext);

    let { darkmode } = useContext(ThemeContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden">
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-10 space-y-reverse md:space-y-0 ">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="pt-3 smooth relative">
                                <Mountatain className="fill-stone-800 dark:fill-stone-300 smooth absolute bottom-0 left-0" />
                                <h1 className="leading-[.9] relative text-center lg:text-right">
                                    <span className="font-dg text-stone-500 dark:text-[#f7f7f7] smooth text-[3.5rem] lg:text-[4.5rem]">
                                        م/ أحمد عادل
                                        <br />
                                        <div className="flex gap-4 items-center">
                                            <img
                                                src={oldPaperIcon}
                                                alt="oldPaperIcon"
                                                className="h-8 hidden md:block"
                                            />
                                            <span className=" text-xl md:text-2xl text-yellow-500">
                                                #كلام ـ مؤرخين .. #ثانوية ـ عامة{" "}
                                            </span>
                                        </div>
                                    </span>
                                </h1>
                                {/* <div className="h-20 w-1 bg-yellow-500"></div> */}
                                <div className="pt-10 space-y-2 font-lama flex-center-both flex-col">
                                    {!id ? (
                                        <>
                                            <p className="text-stone-500 dark:text-stone-100 smooth gap-1 flex-col flex items-center lg:justify-start lg:items-start sm:gap-3 w-full text-center md:text-right text-xl lg:text-3xl">
                                                <span>
                                                    <span className="font-w-bold">نبدأ</span> من هنا
                                                    ... عشان التاريخ{" "}
                                                </span>
                                                <span>
                                                    هو المفتاح لكل{" "}
                                                    <span className="font-w-bold">حاجة</span>
                                                </span>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-center md:text-right text-2xl lg:text-4xl font-cairo">
                                                اهلا بيك طالبي الغالي
                                                <br />
                                                تابع اخر كورسات
                                                <span className="text-flame-500"> {title} </span>
                                            </p>
                                        </>
                                    )}
                                </div>

                                <div className="btn-holder flex justify-center md:justify-start clr-white pt-10">
                                    <Link
                                        className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-stone-500 before:border-t-stone-500 after:border-r-stone-500 after:border-b-stone-500 "
                                        to={`${token ? "/me/user/courses" : "/register"}`}
                                    >
                                        <div className="bg-stone-500 smooth px-8 py-3 flex-center-both gap-4">
                                            <img
                                                src={oldPaperIcon}
                                                alt="oldPaperIcon"
                                                className="h-6"
                                            />{" "}
                                            <span className="text-link  text-lg">
                                                {token ? <>كورساتي </> : <>انضم لعيلتنا</>}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both pt-20 md:pt-0">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
