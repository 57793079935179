import React, { useEffect, useState } from "react";
import Heading from "../../components/ui/Heading";
import Container from "../../components/ui/Container";
import http from "../../services/httpServices";
import CourseFollowUpSection from "../../components/ui/CourseFollowUpSection";
import ProgressCircle from "../../components/ui/ProgressCircle";
import CenterIcon from "../../components/ui/CenterIcon";
import Form from "../../components/form/elements/Form";
import InputField from "../../components/form/elements/InputField";
import { handleFormErrors, handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";
import QuestionSvg from "../../assets/parent-dashboard-assets/QuestionSvg.svg";
import PhyDecore from "../../assets/parent-dashboard-assets/PhyDecore.png";
import { ReactComponent as SideNavCollabseIcon } from "../../assets/parent-dashboard-assets/Arrows.svg";
import profile from "../../assets/adel-assets/HeroImg.png";
import oldPaperIcon from "../../assets/adel-assets/OldPaperIcon.png";
import { ReactComponent as Mountatain } from "../../assets/adel-assets/moutanis.svg";
import OldPaper from "../../assets/adel-assets/patternHistory.png";
import oldBG from "../../assets/adel-assets/oldBg.png";
import oldBgCard1 from "../../assets/adel-assets/oldCardBg1.png";
import oldBgCard2 from "../../assets/adel-assets/oldCardBg2.png";

import { ReactComponent as Tree } from "../../assets/adel-assets/tree.svg";

import MainSection from "../../sections/home/MainSection";
const ParentDashboard = () => {
    const [followUpData, setFollowUpData] = useState({ courses: [] });
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [data, setData] = useState({ phone: "", father_phone: "" });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const getParentDashboardData = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            await http.get("/sanctum/csrf-cookie");
            const result = await http.post("api/parent_dashboard", {
                phone: parseInt(data["phone"]),
                father_phone: parseInt(data["father_phone"]),
            });
            setFollowUpData(result.data);
            setSelectedCourse([]);
        } catch (error) {
            handleFormErrors(error, setIsLoading, setErrors);
        }
    };

    const handleCourseSelection = (course) => {
        setSelectedCourse(course);
    };

    return (
        <Container className="">
            <div className="flex flex-col items-center justify-center h-fit gap-10">
                <HeroSection />
            </div>
            {!followUpData.full_name ? (
                <div className="relative">
                    <div className=" absolute inset-0 -z-10 flex justify-evenly flex-col">
                        <div className=" w-screen  h-2 bg-stone-500  " />
                        <div className=" w-screen  h-2 bg-stone-500  " />
                    </div>
                    <div>
                        <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute -top-14 left-[10%]" />
                        <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute -top-14 right-[10%]" />
                        <Tree className="w-20 h-20 fill-stone-700 dark:fill-stone-200 dark:opacity-40 smooth absolute hidden md:block -top-14 right-1/2" />
                    </div>
                    <section className="relative pb-20 flex justify-center w-full bg-transparent overflow-hidden ">
                        <div className="w-[98%] sm:w-[90%] min-h-[500px] smooth border border-black flex-center-both flex-col relative z-10 py-14 sm:p-14">
                            {/* Start build content  */}
                            <div
                                className="w-full h-full z-10 top-0 bottom-0 left-0 right-0 absolute opacity-20"
                                style={{
                                    backgroundImage: "url(" + OldPaper + ")",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center center",
                                    // backgroundRepeat: "repeat-both",
                                }}
                            ></div>
                            <div
                                className="w-full h-full z-0 top-0 bottom-0 left-0 right-0 absolute opacity-100"
                                style={{
                                    backgroundImage: "url(" + oldBG + ")",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    // backgroundRepeat: "repeat-both",
                                }}
                            ></div>
                            <div className="absolute inset-0 z-10 bg-black/0 smooth dark:bg-black/40 " />

                            <div className="w-full  relative z-10 flex justify-center py-8 flex-wrap gap-8">
                                <Form className=" w-[90%] md:w-1/2 mx-auto bg-stone-100 dark:bg-stone-900/70 rounded-md  gap-5">
                                    <div className="space-y-5 p-8 rounded-md shadow-xl">
                                        <InputField
                                            placeholder="رقم هاتف نجلك"
                                            id="phone"
                                            onChange={handleInputChange}
                                            data={data}
                                            errors={errors}
                                            setData={setData}
                                        />
                                        <InputField
                                            placeholder="رقم هاتفك"
                                            id="father_phone"
                                            onChange={handleInputChange}
                                            data={data}
                                            errors={errors}
                                            setData={setData}
                                        />
                                        <Button
                                            color="stone"
                                            className="w-full"
                                            onClick={getParentDashboardData}
                                            isLoading={isLoading}
                                        >
                                            ابدأ المتابعة
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                ""
            )}

            <div className="flex gap-5 flex-col md:flex-row">
                <div className="w-full md:w-fit">
                    {followUpData.full_name ? (
                        <StatisticsSection
                            followUpData={followUpData}
                            onCourseSelect={handleCourseSelection}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="w-full">
                    {selectedCourse && followUpData.full_name ? (
                        <CourseFollowUpSection course={selectedCourse} />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </Container>
    );
};

const HeroSection = () => {
    const [activeWordIndex, setActiveWordIndex] = useState(0);
    const [loopCount, setLoopCount] = useState(0);

    useEffect(() => {
        const totalLoops = 10;
        const totalWords = 3;

        if (loopCount < totalLoops) {
            const interval = setInterval(() => {
                setActiveWordIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % totalWords;
                    if (newIndex === totalWords - 1) {
                        setLoopCount((prevLoop) => {
                            const newLoopCount = prevLoop + 1;
                            return newLoopCount;
                        });
                    }
                    return newIndex;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [activeWordIndex, loopCount]);
    return (
        <div className="py-10 md:py-20">
            <section className="py-10 negative-nav-margin relative overflow-hidden">
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-10 space-y-reverse md:space-y-0 ">
                        <div className=" md:basis-1/2 basis-full flex-center-both">
                            <div className="pt-3 smooth relative">
                                <Mountatain className="fill-stone-800 dark:fill-stone-300 smooth opacity-30 md:opacity-70 absolute bottom-0 left-0" />
                                <h1 className="leading-[.9] relative text-center lg:text-right">
                                    <span className="font-dg text-stone-500 dark:text-[#f7f7f7] smooth text-[3.5rem] lg:text-[4.5rem]">
                                        م/ أحمد عادل
                                        <br />
                                        <div className="flex gap-4 items-center">
                                            <img
                                                src={oldPaperIcon}
                                                alt="oldPaperIcon"
                                                className="h-8 hidden md:block"
                                            />
                                            <span className=" text-xl md:text-2xl text-yellow-500">
                                                #لوحة تحكم .. #ولي الأمر{" "}
                                            </span>
                                        </div>
                                    </span>
                                </h1>
                                {/* <div className="h-20 w-1 bg-yellow-500"></div> */}
                                <div className="pt-10 justify-center md:justify-start space-y-2 font-lama flex items-center md:items-start">
                                    <p className="w-3/4 text-center md:text-right text-2xl lg:text-4xl font-cairo">
                                        تابع تقدم ابنك، نشاطاته، إنجازاته، وأهدافه كلها من هنا.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both pt-20 md:pt-0">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
};

const StatisticsSection = ({ followUpData, onCourseSelect = () => null }) => {
    // console.log("followUpData", JSON.stringify(followUpData));
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <section className={`space-y-10 h-full `}>
            {followUpData.courses.length > 0 ? (
                <>
                    <nav
                        className={`flex flex-row md:flex-col h-full bg-[#F8F8F8] flex-wrap md:flex-nowrap space-x-6 md:space-x-0 dark:bg-slate-900 border border-black rounded-xl p-5 space-y-4 smooth ${
                            isCollapsed ? "w-full md:w-16" : "w-full h-full "
                        }`}
                    >
                        <div className="flex justify-center w-full mb-4">
                            <button
                                onClick={toggleCollapse}
                                className="p-2 rounded-full bg-gray-200 dark:bg-slate-800 smooth hover:bg-gray-300 dark:hover:bg-slate-700"
                            >
                                <SideNavCollabseIcon
                                    className={`w-10 h-auto ${
                                        isCollapsed
                                            ? "rotate-90 md:rotate-180"
                                            : "-rotate-90 md:rotate-0"
                                    } smooth`}
                                />
                            </button>
                        </div>
                        {followUpData.courses.map((course, index) => (
                            <Button
                                key={index}
                                onClick={() => onCourseSelect(course)}
                                state={{ course }}
                                className={`block p-3 text-lg flex-center-both flex-col text-center clr-text-primary font-semibold border-none bg-stone-200 dark:bg-stone-700 smooth hover:bg-stone-300 rounded-lg transition-colors duration-300 ease-in-out ${
                                    isCollapsed ? "text-center" : "text-left"
                                }`}
                            >
                                <span className=""> - {index} - </span>
                                {!isCollapsed && course.name}
                            </Button>
                        ))}
                    </nav>
                </>
            ) : (
                ""
            )}
        </section>
    );
};

const OverviewStatistics = () => {
    return (
        <div>
            <div className="font-w-bold text-center font-h1 pt-5 pb-10 flex-center-both space-x-3 space-x-reverse">
                <CenterIcon icon="arcticons:questionnaire-star" className="text-stone-500" />
                <span>
                    احصائيات إجمالية لنجلك في{" "}
                    <span className="text-stone-500">الكورسات اللي هو مشترك فيها</span>{" "}
                </span>
                <CenterIcon icon="arcticons:questionnaire-star" className="text-stone-500" />
            </div>
            <div className="w-full flex items-center justify-evenly">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 items-start">
                    <ProgressCircle
                        color="stone"
                        unit="فيديو"
                        title="عدد الفيديوهات نجلك شافها"
                        value={5}
                        max={10}
                        textsIndex={3}
                    />
                    <ProgressCircle
                        color="cyan"
                        unit="امتحان و واجب"
                        title="عدد الامتحانات و الواجبات نجلك خلصها"
                        value={5}
                        max={10}
                        textsIndex={2}
                    />
                    <ProgressCircle
                        color="purple"
                        unit={false}
                        title="متوسط نتائج نجلك"
                        value={5}
                        max={10}
                        textsIndex={1}
                    />
                </div>
            </div>
        </div>
    );
};

export default ParentDashboard;
